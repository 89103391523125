import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["check", "completed"];

  initialize() {
    document.addEventListener('todo.deactivate', event => {
      this.deactivate();
    });
    document.addEventListener('sidebar.closed', event => {
      this.deactivate();
    });
    this.activateDefault();
  }

  activateDefault() {
    if (this.isDefault() && !this.isComplete()) {
      this.completed();
      this.activate();
      document.dispatchEvent(new CustomEvent('sidebar.request', {
        detail: {
          url: this.element.dataset.url,
          element: this.element
        }
      }));
    }
  }

  toggleCategory(event) {
    this.element.classList.toggle('closed');
  }

  toggleComplete(event) {
    event.preventDefault();
    event.cancelBubble = true;
    if (this.checkTarget.classList.contains('complete')) {
      this.incompleted();
    }
    else {
      this.completed();
    }

  }

  isDefault() {
    return this.data.get('default') === 'true';
  }

  isComplete() {
    return this.element.dataset.completed === 'true';
  }

  completed() {
    this.checkTarget.classList.toggle('complete', true);
    this.element.dataset.completed = true;
    let final = this.areAllCompleted;
    $.ajax({
      url: this.checkTarget.dataset.url,
      type: 'PUT',
      data: {authenticity_token: $('meta[name=csrf-token]').attr('content'), final:final}
    });
    if (final) {
      this.categoryController.toggleCategory();
    }
  }

  incompleted() {
    this.checkTarget.classList.toggle('complete', false);
    this.element.dataset.completed = false;
    $.ajax({
      url: this.checkTarget.dataset.url,
      type: 'DELETE',
      data: {authenticity_token: $('meta[name=csrf-token]').attr('content')}
    });
  }

  activate(event) {
    document.dispatchEvent(new CustomEvent('todo.deactivate'));
    this.element.classList.toggle('active', true);
  }

  deactivate() {
    this.element.classList.toggle('active', false);
  }

  get areAllCompleted() {
    try {
      return this.categoryController.areAllCompleted;
    }
    catch {
      return false;
    }
  }

  get categoryController() {
    let category = this.element.closest('.to-do-category');
    return this.application.getControllerForElementAndIdentifier(category, 'todo-category');
  }
}
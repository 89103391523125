import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['content'];

  initialize() {
    console.log('hello from pinned content');
    if (this.hasContentTarget) {
      this.target = this.contentTarget;
    }
    else {
      this.target = this.element;
    }
    this.pinned = false;
    this.createObserver();
    document.addEventListener('sidebar.opened', event => {
      if (this.pinned) {
        this.pin();
      }
    });
  }

  pin() {
    console.log('pinning');
    if (this.element.offsetWidth > 0) {
      console.log('width is greater than zero');
      console.log(this.element.offsetWidth);
      this.current = {width:this.target.style.width, position:this.target.style.position, top:this.target.style.top, z:this.target.style.zIndex};
      this.target.style.width = this.target.offsetWidth + 'px';
      this.target.style.position = 'fixed';
      this.target.style.zIndex = 1000;
      if (this.data.get('top')) {
        this.target.style.top = parseInt(this.data.get('top')) + 'px';
      }
      this.pinned = true;
    }
  }

  unpin() {
    console.log('unpinning');
    if (this.pinned) {
      console.log(this.current);
      this.target.style.position = this.current.position;
      this.target.style.width = this.current.width;
      this.target.style.top = this.current.top;
      this.target.style.zIndex = this.current.z;
      this.pinned = false;
    }
  }

  createObserver() {
    //set the sentinel
    let sentinel = document.createElement("DIV");
    sentinel.classList.toggle('sidebar-sentinel', true);
    sentinel.style.cssText = "position: relative; width: 0px; height: 0px;";
    let parent = this.element.parentNode;
    parent.insertBefore(sentinel, this.element);

    this.observer = new IntersectionObserver((entries, observer) => {
      let entry = entries[0];
      if (entry.intersectionRatio > 0) {
        this.unpin();
      }
      else {
        this.pin();
      }
    });
    this.observer.observe(sentinel);
  }
}
import {Controller} from "stimulus";
import Fetch from "../modules/fetch"

export default class extends Controller {
  static targets = ["content"];

  initialize() {
    new Fetch(this.url, this.update.bind(this));
  }

  update(response) {
    if (response.status === 200) {
      this.target.innerHTML = response.data;
    }
  }

  get target() {
    if (this.hasContentTarget) {
      return this.contentTarget;
    }
    return this.element;
  }

  get url() {
    return this.data.get('url');
  }

}
import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["content"];

  initialize() {
    let controller = this;
    $.get(this.url).done(function (response, status) {
      if (status === 'success') {
        controller.update(response);
      }
      if (status === 'nocontent') {
        controller.fallback();
      }
    });
  }

  update(html) {
    if (this.hasContentTarget) {
      this.contentTarget.innerHTML = html;
    } else {
      this.element.innerHTML = html;
    }
  }

  fallback() {
    if (this.fallbackPath !== '') {
      this.update('<div class="story-utility" data-controller="story-detail" data-story-detail-url="' + this.fallbackPath + '"></div>');
    }
  }

  get url() {
    return '/utilities/rate_comparison/' + this.commodity;
  }

  get commodity() {
    return this.data.get('commodity');
  }

  get fallbackPath() {
    return this.data.get('fallback');
  }
}
import {Controller} from "stimulus";
import Fetch from "../modules/fetch"

export default class extends Controller {
  static targets = ["branches", "officeGroup", "office", "state", "leads"];

  initialize() {
    console.log('Hello from sticky toggle')
    this.isOn = this.targets[0].classList.contains(this.css);
  }

  toggle(event) {
    this.isOn = !this.isOn;
    if (this.toggleSelf) {
      this.element.classList.toggle(this.css);
    }
    this.targets.forEach(element => {
      element.classList.toggle(this.css);
    })
    new Fetch(this.data.get('url'), this.fetchCallback, {method:'PUT', body:{state:this.state}})
  }

  fetchCallback(response) {
    console.log(response);
  }

  remove() {
    if (this.toggleSelf) {
      this.element.classList.remove(this.css);
    }
    this.targets.forEach(element => {
      element.classList.remove(this.css);
    })
  }

  get state() {
    return this.isOn ? this.css : '';
  }

  get targets() {
    return document.querySelectorAll(this.target);
  }

  get target() {
    return this.data.get('target') || this.element.dataset.target;
  }

  get css() {
    return this.data.get('class') || 'open'
  }

  get toggleSelf() {
    return this.data.get('self') === 'true';
  }
}
import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["todo"];

  initialize() {

  }

  toggleCategory(event) {
    this.element.classList.toggle('closed');
  }

  get areAllCompleted() {
    let completed = true;
    this.todoTargets.forEach((todo, index) => {
      if (todo.dataset.completed !== 'true') {
        completed = false;
      }
    });
    return completed;
  }
}
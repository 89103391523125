import {Controller} from "stimulus";
import Fetch from '../modules/fetch';

export default class extends Controller {

  initialize() {
    this.boundAnswered = this.questionAnswered.bind(this);
  }

  connect() {
    document.addEventListener('ppc.question.answered', this.boundAnswered)
  }

  disconnect() {
    document.removeEventListener('ppc.question.answered', this.boundAnswered)
  }

  questionAnswered(event) {
    if (event.detail.type === 'update') {
      document.dispatchEvent(new CustomEvent('ppc.question.activate_last'));
    }
    else {
      new Fetch(this.data.get('url'), this.fetchCallback.bind(this))
    }
  }

  fetchCallback(response) {
    if (response.status === 204) {
      window.location.reload();
    }
    if (response.status === 200) {
      let fragment = document.createRange().createContextualFragment(response.data);
      this.element.append(fragment);
      document.dispatchEvent(new CustomEvent('ppc.question.activate_last'));
    }
  }
}
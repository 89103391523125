import {Controller} from "stimulus";
import Fetch from '../modules/fetch';

export default class extends Controller {

  initialize() {
    this.boundActivate = this.activate.bind(this);
    this.boundDeactivate = this.deactivate.bind(this);
  }

  connect() {
    document.addEventListener('ppc.question.activate_last', this.boundActivate)
    document.addEventListener('ppc.question.deactivate', this.boundDeactivate)
  }

  disconnect() {
    document.removeEventListener('ppc.question.activate_last', this.boundActivate)
    document.removeEventListener('ppc.question.deactivate', this.boundDeactivate)
  }

  activate(event) {
    if (event.type === 'ppc.question.activate_last') {
      if (this.element.nextElementSibling == null) {
        this.element.classList.add('active');
      }
      else {
        this.element.classList.remove('active');
      }
    }
    else {
      if (!this.isActive) {
        if (confirm('Would you like to update this question?')) {
          document.dispatchEvent(new CustomEvent('ppc.question.deactivate'));
          this.element.classList.add('active');
        }
      }
    }
  }

  deactivate(event) {
    this.element.classList.remove('active');
  }

  answerQuestion(event) {
    let method = this.isUpdate ? 'PUT' : 'POST'
    new Fetch(this.data.get('url'), this.fetchCallback.bind(this), {method: method, body: {onboard_question_id:this.id, onboard_response: {response_value:event.currentTarget.value}}})
  }

  fetchCallback(response) {
    if (response.status === 200) {
      document.dispatchEvent(new CustomEvent('ppc.question.answered', {
        detail: {
          type: this.action
        }
      }));
      if (!this.isUpdate) {
        this.data.set('action', 'update');
        this.data.set('url', response.data)
      }
    }
  }

  get isActive() {
    return this.element.classList.contains('active');
  }

  get isUpdate() {
    return this.action === 'update';
  }

  get action() {
    return this.data.get('action');
  }

  get id() {
    return this.data.get('id');
  }

}
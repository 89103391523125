import {Controller} from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["input", "month", "day", "days", "date"];

  initialize() {
    console.log('extending flatpickr controller');
    this.ms_per_day = 1000 * 60 * 60 * 24;
    this.fp = flatpickr(this.inputTarget, {
      onChange: (selectedDates, dateStr, instance) => {
        this.onChange(selectedDates, dateStr, instance);
      }
    });
  }

  openCalendar() {
    this.fp.open();
  }

  onChange(selectedDates, dateStr, instance) {
    this.date = selectedDates[0];
    this.updateDisplay(dateStr);
    this.sendDate(dateStr);
  }

  sendDate(date) {
    $.ajax({
      url: '/moving_date',
      type: 'PUT',
      data: {authenticity_token: $('meta[name=csrf-token]').attr('content'), moving_date:date}
    });
  }

  updateDisplay(string) {
    this.inputTarget.value = string;
    if (this.hasDateTarget) {
      this.dateTarget.innerText = this.fp.formatDate(this.date, 'n/j');
      this.daysTarget.innerHTML = this.daysString.replace('{{days}}', this.daysUntilMove);
    }
    if (this.hasDayTarget) {
      this.monthTarget.innerText = this.fp.formatDate(this.date, 'F')
      this.dayTarget.innerText = this.date.getDate();
      this.daysTarget.innerHTML = '<span class="move-day-number">' + this.daysUntilMove + '</span> ' + this.daysString;
    }
  }

  get daysUntilMove() {
    // Discard the time and time-zone information.
    let today = new Date();
    const moveDateUTC = Date.UTC(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
    return Math.abs(Math.floor((todayUTC - moveDateUTC) / this.ms_per_day));
  }

  get daysString() {
    if (this.date > new Date()) {
      return JSON.parse(this.data.get('future'));
    }
    return JSON.parse(this.data.get('past'));
  }

}
import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["input", "meter", "help", "firstName", "lastName", "email", "title"];

  initialize() {
    import("zxcvbn").then(zxcvbn => {this.zxcvbn = zxcvbn.default; console.log(zxcvbn)});
    this.inputTarget.addEventListener('keyup', event => {
      this.printStrength(event);
    });
  }

  printStrength(event) {
    if (this.inputTarget.value.length > 4) {
      let score = this.score;
      this.inputTarget.dataset.strength = score.score;
      let meter = this.meter;
      meter.setAttribute('class', 'strength-meter');
      meter.classList.toggle(this.strengthClass(score.score), true);
      this.helpText.innerHTML = score.feedback;
    }
  }

  strengthClass(score) {
    const classes = ['weak', 'weak', 'medium', 'strong', 'very_strong'];
    return classes[score];
  }

  get meter() {
    if (this.hasMeterTarget) {
      return this.meterTarget;
    }
    let meter = document.createElement("DIV");
    meter.setAttribute('data-target', 'password-strength.meter');
    let parent = this.inputTarget.parentElement;
    parent.appendChild(meter);
    return meter;
  }

  get helpText() {
    return this.helpTarget;
  }

  get score() {
    if (this.inputTarget.value.length < 8) {
      return {score:0, feedback:'Too short'};
    }
    let strength = this.zxcvbn(this.inputTarget.value, this.inputValues);
    if (strength.score >= 3) {
      if (strength.crack_times_seconds.offline_slow_hashing_1e4_per_second > 31556952) {
        return {score: strength.score, feedback: 'Strong'}
      }
      else {
        return {score: 3, feedback: 'Add another word or two. Uncommon words are better.'}
      }
    }
    if (strength.feedback.warning == '') {
      return {
        score: strength.score,
        feedback: strength.feedback.suggestions.join(' ')
      }
    }
    return {score:strength.score, feedback:strength.feedback.warning}
  }

  get inputValues() {
    let result = [this.firstName, this.lastName, this.email, this.title];
    let email = this.email.split('@');
    email.forEach((part, index) => {
      result.push(part);
    });
    return result;
  }

  get firstName() {
    if (this.hasFirstNameTarget) {
      return this.firstNameTarget.value;
    }
    return this.data.get('first-name');
  }

  get lastName() {
    if (this.hasLastNameTarget) {
      return this.lastNameTarget.value;
    }
    return this.data.get('last-name');
  }

  get email() {
    if (this.hasEmailTarget) {
      return this.emailTarget.value;
    }
    return this.data.get('email');
  }

  get title() {
    if (this.hasTitleTarget) {
      return this.titleTarget.value;
    }
    return this.data.get('title');
  }
}
import {Controller} from "stimulus";
import Fetch from "../modules/fetch"

export default class extends Controller {
  initialize() {
    console.log('hello from market switch')
  }

  sendMarket() {
    new Fetch(this.url, this.marketUpdated, {method:'PUT', body:{user:{market_id:this.element.value}}});
  }

  marketUpdated(response) {
    if (response.status === 200) {
      window.location.reload();
    }
  }

  get url() {
    return this.data.get('url')
  }

}
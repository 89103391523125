import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["container", "sidebar", "title", "content"];

  initialize() {
    document.addEventListener('sidebar.request', event => {
      this.open(event, true);
    });
    this.isOpen = false;
  }

  open(event, detail) {
    event.preventDefault();
    let target = detail ? event.detail.element : event.currentTarget;
    if (this.isOpen) {
      if (target == this.currentTrigger) {
        return true;
      }
    }
    let url = target.dataset.url || target.href;
    if (url == null) {
      return false;
    }
    this.currentTrigger = target;
    let controller = this;
    $.get(url).done(function(response, status) {
      if (status === 'success') {
        controller.setOpen(response, controller.currentTrigger.dataset.title);
      }
    });
  }

  setOpen(content, title) {
    if (this.hasTitleTarget && title !== null) {
      this.titleTarget.innerHTML = title;
    }
    this.contentTarget.innerHTML = content;
    this.containerTarget.classList.toggle('open', true);
    if (this.currentTrigger.offsetTop > 75) {
      this.sidebarTarget.style.position = 'relative';
      this.sidebarTarget.style.top = (this.currentTrigger.offsetTop - 75) + 'px';
      this.sidebarTarget.style.marginBottom = (this.currentTrigger.offsetTop - 55) + 'px';
    }
    else {
      this.sidebarTarget.style.top = '0';
      this.sidebarTarget.style.marginBottom = '0';
    }
    this.isOpen = true;
  }

  close(event) {
    if (this.currentTrigger != null) {
      document.dispatchEvent(new CustomEvent('sidebar.closed'));
    }
    this.contentTarget.innerHTML = '';
    if (this.hasTitleTarget) {
      this.titleTarget.innerHTML = '';
    }
    this.containerTarget.classList.toggle('open', false);
    this.isOpen = false;
    this.currentTrigger = null;
  }
}